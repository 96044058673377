import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'

// markup
const UsedVacheronConstantinWatches = () => {
  const data = useStaticQuery(
    graphql`
      query queryUsedVacheronConstantinWatches {
        products: allStrapiProduct(
          filter: { brand: { eq: "Vacheron Constantin" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Vacheron Constantin Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/vacheron-constantin/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Vacheron Constantin Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
              <StaticImage
                src="../../images/preowned/used-vacheron-constantin-web-banner.jpg"
                alt="Pre-Owned Certified Used Vacheron Constantin Watches Header"
                aria-label="Used Vacheron Constantin Watches Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div>
            <h1 className="text-3xl">
              Buy Sell Trade Consign Repair Restore Vacheron Constantin Watches
            </h1>
            <h2 className="p-5 lg:px-0 w-full flex justify-center">
              History of Vacheron Constantin Watches
            </h2>
            <p>
              Tracing back to 1755 in Geneva, Switzerland, Jean-Marc Vacheron founded the world’s
              oldest luxury timepiece manufacturer still relevant in the present day. Since its
              launch, Vacheron Constantin has been of high regard and coveted by the elite members
              of society. In times of custom-made pocket watches, Vacheron would often be
              commissioned by the likes of kings and nobles such as Louis XIV, Czar Alexander II and
              the Czarina, and many more. When the French Revolution hit in 1789, the brand was
              troubled with balancing out the supply when in absence of demand. Many other
              watchmakers struggled to keep their businesses alive, however, with the help of
              Francois Constantin, Vacheron expanded their shops throughout Europe and the Americas
              with the expression “Do better if possible, which is always possible”. Since this
              union of Vacheron &amp; Constantin, the fine Swiss watchmaking brand has been a huge
              success. Fast forward to the mid-1800s, the demand for quality timekeeping pieces has
              skyrocketed. This calls for technological advancement in Vacheron Constantin’s
              watchmaking department; machine production began taking over manual labor. Technical
              director Georges-Auguste Leschot produces machinery which fabricates serial
              production, further accelerating the quantity of supply. Vacheron Constantin
              registered the Maltese Cross as their official logo in 1880 at the Swiss Federal
              Institute of Intellectual Property in Berne. In 1903 the Wright Brothers commissioned
              Vacheron Constantin to create a portable pilot’s watch to use on their ‘Wright Flyer’
              biplane. Innovator George Grandjean, who took over management of VC after Francois
              Constantin’s death, designed a utilitarian timepiece powered by a manual-wind movement
              and equipped with a chronograph useful to flight pioneers. In the 1910s, Vacheron
              Constantin brought back the production of pocket watches alongside new releases of
              wristwatches, many using movement ebauches from{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/"> LeCoultre.</a> Through
              the years until the 30s, VC crafts some iconic pieces with history-making
              complications. Examples are the James Ward Packard alarm pocket watch and the Fuad I
              18k yellow gold complication Pocket watch equipped with a double chronograph calendar,
              power reserve display, alarm, minute-repeater, and a Grande/Petite Sonnerie. Today,
              Vacheron Constantin is known to continue to make groundbreaking timepieces,
              collections, and models for any and all consumers.
            </p>

            <h2 className="text-3xl">
              Buying and Selling{' '}
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Watches
              </a>
            </h2>

            <h2>
              Since its release, the Vacheron Constantin has been a huge success. These elegant
              timepieces draw the attention of any watch enthusiast that comes near them. The
              Vacheron Constantin collection is one of Vacheron Constantin’s versatile pieces
              appropriate for almost any setting. Under the umbrella of Vacheron Constantin watches
              fall under the following references:
            </h2>

            <h2 className="text justify mx-auto text-2xl">
              Popular Vacheron Constantin watches and/or References:
            </h2>

            <li>
              {' '}
              &ensp;
              <a href="/w525880-unused-vacheron-constantin-overseas-4/">
                Pre-Owned Vacheron Constantin Overseas References: 2305v/100a-b170, 4500V/110A-B128,
                4500V/110R-B705
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Harmony References: 7800S/000R-B140,
                7805S/000R-B140,7805S/000G-B052
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/w525546-vacheron-constantin-fiftsix-40mm-4600/">
                Pre-Owned Vacheron Constantin Fiftysix References: 4000E/000A-B548, 4000E/000R-B438,
                4000E/000R-B065
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Patrimony References: 81180/000R-B518,
                1110U/000R-B085, 82172/000R-9382, 80172/000P-9505
              </a>{' '}
            </li>

            <h2 className="text-2xl mx-auto">
              Gray &amp; Sons is the Best Source for Buying and Selling{' '}
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Watches{' '}
              </a>{' '}
            </h2>
            <p>
              {' '}
              Since 1980, Gray and Sons Jewelers has been catering to clients all around the country
              with all their luxury needs. All of the services we provide are done in our very own
              store, here, in the heart of Miami, Florida. We are the top watch store in all of
              South Florida specializing in buying pre-owned Vacheron Constantin watches, Selling
              used Vacheron Constantin watches, repairing second-hand Vacheron Constantin watches,
              and trading genuine Vacheron Constantin timepieces. Gray and Sons Jewelers is the #1
              all-stop shop for all Vacheron Constantin watch needs. Our store is located in South
              Florida in the city of Surfside across the street from the famous Bal Harbour Shops.
              Aside from buying Vacheron Constantin Watches and selling genuine pre-owned Vacheron
              Constantin watches, Gray and Sons is also a certified repair center for all Swiss-made
              wristwatches. We have six master-trained watchmakers in-house with over 150 years of
              combined experience to repair Vacheron Constantin watches, including the reparation of
              a Vacheron Constantin. We take in repairs for swiss-made watches daily! Those looking
              to “get cash for my used Vacheron Constantin watch” or “sell my pre-owned Vacheron
              Constantin watch today” should visit <a href="/"> www.grayandsons.com </a> or{' '}
              <a href="https://sellusyourjewelry.com/"> www.sellusyourjewelry.com </a> to request a
              free watch quote to sell or trade-in for one of ours! We have a brick-and-mortar store
              located in the heart of Miami, Fl near cities like Sunny Isles, Haulover, Aventura,
              Hollywood, Fort Lauderdale, Miami Beach, Coconut Grove, and Coral Gables, Key
              Biscayne, and many more. On our website, we have LIVE representatives Viktoria and
              Rich who are specialists in buying and selling Vacheron Constantin watches. Our LIVE
              CHAT feature allows clients all around the nation to make all inquiries about Vacheron
              Constantin watches and possibly work out a deal in real-time. Gray and Sons Jewelers
              is the best and most reliable place to buy used pre-owned Vacheron Constantin watches.
              Our master-trained watchmakers assure every Vacheron Constantin watches in our
              inventory is certified authentic and serviced ready for wear with a 1-year warranty
              covering the movement.
            </p>
          </div>
        </section>
      </div>
      <div className="w-full flex mx-auto justify-center pb-24 pt-24">
        <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
          <button>Shop Vacheron Constantin Watches</button>{' '}
        </a>
      </div>

      <section>
        <h2 className="text-center pb-48 ">IN-STOCK Vacheron Constantin Watches:</h2>

        <iframe
          className="content-center"
          src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d7178.937737903439!2d-80.1235056!3d25.8869528!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x88d9b2992b408533%3A0x8d702d6011cda310!2sGray%20and%20Sons%20Jewelers%7C%20Luxury%20Estate%20Watches%20%26%20Jewelry%209595%20Harding%20Ave%20Surfside%2C%20FL%2033154!3m2!1d25.8869528!2d-80.1235056!5e0!3m2!1sen!2sus!4v1655907861510!5m2!1sen!2sus"
          width="1380"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <br />

        <ProductGrid products={data.products.nodes} />
      </section>

      <section></section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/F_VZZhwYmGY'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default UsedVacheronConstantinWatches
